import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { colors } from '../../../primitives/colors';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import VisuallyHidden from '../../../primitives/visually-hidden';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import BasicSlider, { useSliderContext } from '../../../basic-slider';
import MediaItem from '../../../primitives/media-item';
import ColSet from '../../../primitives/grid/col-set';
import Typography from '../../../primitives/typography';
import AspectRatio from '../../../primitives/aspect-ratio';
import FadeTransition from '../../../animations/transitions/fade-transition';
import DatoAsset from '../../../primitives/asset';
import VideoPopover from '../../../primitives/video-popover';
import FadeTransitionFramer from '../../../animations/transitions/fade-transition-framer';

export type QuoteCarouselProps = {};

const QuoteCarouselSelector = ({ items }: QuoteCarouselProps): JSX.Element => {
    const id = React.useId();

    const { jumpTo, page } = useSliderContext();
    const slideIndex = wrap(0, items.length, page);

    return (
        <div css={{
            maxWidth: '80vw',
            overflowX: 'hidden',
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '4px',
            paddingRight: '4px',
        }}
        >
            <ColSet breakpoints={{ dt: { between: 12 } }}>
                {items.map((item, idx) => (
                    <button
                        type="button"
                        key={idx}
                        css={{
                            height: '55px',
                            width: '40px',
                            borderRadius: borderRadius.medium,
                            transition: '0.3s',
                            position: 'relative',
                        }}
                        onClick={() => {
                            jumpTo(idx);
                        }}
                    >
                        {item.thumbnail ? (
                            <DatoAsset
                                css={{
                                    borderRadius: borderRadius.medium,
                                    userSelect: 'none',
                                    height: '100%',
                                    width: '100%',
                                }}
                                asset={item.thumbnail}
                                isStatic
                            />
                        ) : (
                            <MediaItem
                                css={{
                                    borderRadius: borderRadius.medium,
                                    userSelect: 'none',
                                    height: '100%',
                                    width: '100%',
                                }}
                                item={item.mediaItem}
                                isStatic
                            />
                        )}
                        {slideIndex === idx && (
                            <motion.div
                                layoutId={id}
                                css={{
                                    boxShadow: `0 0 0 2px ${colors.primaryOrange}`,
                                    border: `2px solid ${colors.shadesWhite}`,
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    top: '0',
                                }}
                                style={{
                                    borderRadius: borderRadius.medium,
                                }}
                                transition={{
                                    type: 'spring',
                                    duration: 0.6,
                                }}
                            />
                        )}
                        <VisuallyHidden>
                            Display quote number #
                            {idx + 1}
                        </VisuallyHidden>

                    </button>
                ))}
            </ColSet>
        </div>
    );
};

const SlideQuote = ({ items }): JSX.Element => {
    const { page } = useSliderContext();
    const slideIndex = wrap(0, items.length, page);
    const item = items[slideIndex];
    return (
        <FadeTransitionFramer shouldChange={slideIndex.toString()}>
            <div css={{
                textAlign: 'left',
                [breakpoints.mb]: {
                    textAlign: 'center',
                },
            }}
            >
                <Typography
                    fontSize={{
                        dt: 4453,
                        tb: 3234,
                        mb: 2429,
                    }}
                    fontWeight="light"
                    override
                >
                    <div>“</div>
                    <CustomStructuredTextRenderer
                        data={item.quote}
                    />
                </Typography>

                <Typography
                    css={{
                        paddingTop: '40px',
                        [breakpoints.mb]: {
                            paddingTop: '36px',
                        },
                    }}
                    fontSize={{
                        dt: 1822,
                    }}
                    override
                >
                    <span>{item.attribution}</span>
                    <br css={{
                        display: 'none',
                        [breakpoints.mb]: {
                            display: 'unset',
                        },
                    }}
                    />
                    <span css={{ marginLeft: '12px', opacity: 0.5 }}>{item.role}</span>
                </Typography>
            </div>
        </FadeTransitionFramer>
    );
};

const SlideMedia = ({ items, setAutoPlay }: QuoteCarouselProps & { setAutoPlay: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element => {
    const { page } = useSliderContext();
    const slideIndex = wrap(0, items.length, page);
    return (
        <div
            css={{
                borderRadius: borderRadius.large,
                boxShadow: '0 17px 44px rgba(0, 0, 0, 0.27)',
                backgroundColor: colors.primaryDarkPurple,
            }}
        >
            <AspectRatio breakpoints={{
                dt: { height: 520, width: 318 },
            }}
            >
                <div css={{ display: 'flex', height: '100%', width: '100%' }}>
                    <FadeTransition shouldChange={slideIndex} css={{ width: '100%' }}>
                        {items && items[slideIndex] && (
                            <div
                                onClick={() => {
                                    setAutoPlay(false);
                                }}
                                css={{
                                    width: '100%',
                                }}
                            >
                                <VideoPopover video={items[slideIndex].mediaItem?.[0]?.media} showPlayButton>
                                    {items[slideIndex]?.thumbnail ? (
                                        <DatoAsset
                                            css={{
                                                userSelect: 'none',
                                                borderRadius: borderRadius.large,
                                                overflow: 'hidden',
                                                transform: 'translateZ(0px)',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                            asset={items[slideIndex].thumbnail}
                                            isStatic
                                        />
                                    ) : (
                                        <MediaItem
                                            css={{
                                                userSelect: 'none',
                                                borderRadius: borderRadius.large,
                                                overflow: 'hidden',
                                                transform: 'translateZ(0px)',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                            isStatic
                                            item={items[slideIndex].mediaItem}
                                            onPlayStateChange={(state) => {
                                                if (state === 'play') {
                                                    setAutoPlay(false);
                                                } else if (state === 'pause') {
                                                    setAutoPlay(true);
                                                }
                                            }}
                                        />
                                    )}
                                </VideoPopover>
                            </div>
                        )}
                    </FadeTransition>
                </div>

            </AspectRatio>
        </div>
    );
};

const QuoteCarousel = (): JSX.Element => {
    const [autoPlay, setAutoPlay] = React.useState<boolean>(true);

    const data = useStaticQuery<Queries.QuoteCarouselQuery>(graphql`
        query QuoteCarousel {
            allDatoCmsQuote {
                nodes {
                    position
                    quote {
                        blocks
                        links
                        value
                    }
                    attribution
                    role
                    mediaItem {
                        ...MediaItemProps
                    }
                    thumbnail {
                        ...DatoCmsInternalAsset
                    }
                }
            }
        }
    `);

    const items = data?.allDatoCmsQuote?.nodes.sort((a, b) => a.position - b.position) || [];

    return (
        <BasicSlider autoPlay={autoPlay ? 6 : 0}>
            <Container css={{
                marginTop: '96px',
                marginBottom: '120px',
                [breakpoints.mb]: {
                    marginTop: '45px',
                    marginBottom: '75px',
                },
            }}
            >
                <Row>
                    <Col breakpoints={{ dt: { span: 8 }, tb: { span: 6 } }}>

                        <div css={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            height: '100%',
                            marginTop: '62px',
                            [breakpoints.mb]: {
                                alignItems: 'center',
                            },
                        }}
                        >
                            <div />
                            <div>
                                <SlideQuote items={items} />
                            </div>
                            <div css={{
                                paddingTop: '65px',
                                [breakpoints.mb]: {
                                    paddingTop: '22px',
                                },
                            }}
                            >
                                <QuoteCarouselSelector items={items} />
                            </div>
                        </div>
                    </Col>
                    <Col breakpoints={{ dt: { span: 4 }, tb: { span: 6 }, mb: { span: 4, hidden: true } }}>
                        <div css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                        >
                            <SlideMedia items={items} setAutoPlay={setAutoPlay} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </BasicSlider>
    );
};

export default QuoteCarousel;
